import api from '~/services/api';
import { connect } from '~/services/iot.services';
import { API_BROWSE } from '~/services/app.config';

export const connectChannel = (user, redirect) =>
  new Promise((resolve) => {
    api({
      method: 'POST',
      url: `${API_BROWSE}/social/link`,
      data: { user, redirect }
    })
      .then((response) => {
        // console.log('response_', response);
        if (response.data && response.data.success) {
          resolve(response.data.success);
        } else resolve(false);
      })
      .catch((e) => {
        resolve(false);
      });
  });

export const unlinkChannel = (user) =>
  new Promise((resolve) => {
    api({
      method: 'POST',
      url: `${API_BROWSE}/social/unlink`,
      data: { user }
    })
      .then((response) => {
        // console.log('response_', response);
        if (response.data && response.data.success) {
          resolve(response.data.success);
        } else resolve(false);
      })
      .catch((e) => {
        resolve(false);
      });
  });

export const getSocialProfile = (user) =>
  new Promise((resolve) => {
    api({
      method: 'GET',
      url: `${API_BROWSE}/social/profile/${user}`
    })
      .then((response) => {
        if (response.data && response.data.success) {
          resolve(response.data.success);
        } else resolve(false);
      })
      .catch((e) => {
        resolve(false);
      });
  });

export const updateSocialProfile = (user, data) =>
  new Promise((resolve) => {
    api({
      method: 'PUT',
      url: `${API_BROWSE}/social/profile/${user}`,
      data: data
    })
      .then((response) => {
        // console.log('updateSocialProfile_', response);
        if (response.data && response.data.success) {
          resolve(response.data.success);
        } else resolve(false);
      })
      .catch((e) => {
        resolve(false);
      });
  });

export const getPost = (user, page = 1, limit = 10, status = '') =>
  new Promise((resolve) => {
    let _limit = status !== '' ? limit : 9999;
    let url = `${API_BROWSE}/social/posts?user=${user}&page=${page}&limit=${_limit}&sortField=publicationMetadata.scheduleDate`;

    if (status !== '') url = url + `&status=${status}&order=-1`;

    api({
      method: 'GET',
      url: url
    })
      .then((response) => {
        // console.log('getPost_response_', response);
        if (response.data) {
          resolve(response.data);
        } else resolve(false);
      })
      .catch((e) => {
        resolve(false);
      });
  });

export const updatePost = (user, taskId, scheduleDate) =>
  new Promise((resolve) => {
    api({
      method: 'PUT',
      url: `${API_BROWSE}/social/post/${user}/${taskId}`,
      data: {
        scheduleDate: scheduleDate
      }
    })
      .then((response) => {
        // console.log('updatePost_response_', response);
        if (response.data && response.data.success) {
          resolve(response.data.success);
        } else resolve(false);
      })
      .catch((e) => {
        resolve(false);
      });
  });

export const getLastScheduleDate = (user) =>
  new Promise((resolve) => {
    let url = `${API_BROWSE}/social/posts?user=${user}&page=1&limit=1&sortField=publicationMetadata.scheduleDate&order=-1`;

    api({
      method: 'GET',
      url: url
    })
      .then((response) => {
        // console.log('getLastScheduleDate', response);
        if (response.data) {
          resolve(response.data);
        } else resolve(false);
      })
      .catch((e) => {
        resolve(false);
      });
  });

export const addToSchedule = (data) =>
  new Promise((resolve) => {
    api({
      method: 'POST',
      url: `${API_BROWSE}/social/schedule`,
      data: data
    })
      .then((response) => {
        // console.log('response_', response);
        if (response.data && response.data.success) {
          resolve(response.data.success);
        } else resolve(false);
      })
      .catch((e) => {
        resolve(false);
      });
  });

export const deleteToSchedule = (userId, taskId, postId = null, reason = 'delete') =>
  new Promise((resolve) => {
    let data = { reason };

    if (postId) data = { postId: postId, reason };

    api({
      method: 'DELETE',
      url: `${API_BROWSE}/social/post/${userId}/${taskId}`,
      data: data
    })
      .then((response) => {
        // console.log('deleteToSchedule_response_', response);
        if (response.data && response.data.success) {
          resolve(response.data.success);
        } else if (response.data && response.data.status && response.data.status === 'success') {
          resolve(true);
        } else resolve(false);
      })
      .catch((e) => {
        resolve(false);
      });
  });

export const socketToSchedule = async () =>
  new Promise((resolve, reject) => {
    connect(
      process.env.AWS_IOT_REGION,
      process.env.AWS_IOT_ENDPOINT,
      process.env.AWS_IOT_ACCESS_ID,
      process.env.AWS_IOT_SECRET_KEY
    )
      .then((iotConnection) => {
        // console.log('iotConnection_', iotConnection);
        resolve(iotConnection);
      })
      .catch((e) => {
        console.log('iotConnection error - ', e);
        reject();
      });
  });
